import {useMutation} from "@tanstack/react-query";
import API from "../services/api";

export default function useDoneRoute() {
    const mutation = useMutation({
        mutationFn: (org) => API.getDoneRoute(org),
        onSuccess: (data) => {
            // Handle success
            console.log("Get done route successfully", data?.data);
        },
        onError: (error) => {
            // Handle error
            console.error("Error geting check-in:", error);
        },
    });

    const { data, mutate: getDoneRoute, isSuccess, error } = mutation;

    return {
        data,
        getDoneRoute,
        isSuccess,
        error,
    };
}